import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { id: String, followUp: { type: Number, default: 14 } };
  static targets = ['close'];

  connect () {
    this.bannerId = `banner_${this.idValue}`;

    let dateShown = window.localStorage.getItem(this.bannerId)
    if (dateShown) {
      dateShown = new Date(parseInt(dateShown));
      // dateShown.setMinutes(dateShown.getMinutes() + 2);
      dateShown.setDate(dateShown.getDate() + this.followUpValue); // Date = Days
      if (dateShown.valueOf() > new Date().valueOf()) return;
    }

    this.element.classList.remove('d-none');
  }

  close () {
    this.element.classList.add('d-none');
    window.localStorage.setItem(this.bannerId, new Date().valueOf());
  }
}
