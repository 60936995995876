import { Controller } from '@hotwired/stimulus';
import Bss from '@erwinstone/bs-select';
import { Dropdown } from 'bootstrap/dist/js/bootstrap.bundle.min.js';

Dropdown.prototype.toggle = ((_orginal) => {
  return function () {
    if (this._parent.querySelectorAll('.dropdown').length > 0 && !this._parent.dataset.nestedDropdownsAttached) {
      this._parent.dataset.nestedDropdownsAttached = true;
      this._element.addEventListener('hide.bs.dropdown', (e) => {
        if (e.clickEvent?.target?.querySelectorAll(':scope > .dropdown-menu').length > 0 || e.clickEvent?.target?.parentNode?.querySelectorAll(':scope > .dropdown-menu').length > 0) {
          e.preventDefault();
        }
      })
    }

    return _orginal.call(this);
  }
})(Dropdown.prototype.toggle);


export default class extends Controller {
  connect () {
    (() => {
      // bootstrap_form will add an empty option if the select is required and there is no prompt or default value
      // so to avoid that, we remove the empty option if it exists
      const option = this.element.querySelector('option');

      if (option && option.value === '' && option.text === '') {
        option.remove();
      }
    })();

    this.dropdown = new Bss(this.element, {
      labels: {
        search: 'Suchen ...',
        noResults: 'Keine Ergebnisse'
      }
    });

    this.element.addEventListener('reset', () => {
      this.dropdown.clear();
    });

    this.element.addEventListener('enable', () => {
      this.dropdown.enable();
    });

    this.element.addEventListener('disable', () => {
      this.dropdown.disable();
    });
  }
}
