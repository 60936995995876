import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    if (document.querySelector('.cf-turnstile') && window.turnstile) {
      window.turnstile.render('.cf-turnstile', {
        callback: (token) => {
          // This function is called when turnstile verification is complete
          console.log('Turnstile verification completed');
          // You can dispatch a custom event if needed
          this.element.dispatchEvent(new CustomEvent('turnstile:success', { 
            detail: { token }, 
            bubbles: true 
          }));
        }
      });
    }
  }
}
