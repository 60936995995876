import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['favoriteButton'];
  static values = {
    id: Number,
    searchId: Number,
    searchQuery: String,
  };

  connect () {
    this.evalFavorite();

    this.element.addEventListener('click', this.click.bind(this));
  }

  async click (e) {
    if (!window.$Gunfinder.app && this.element.nodeName === 'A' && this.searchQueryValue) {
      e.preventDefault();

      const
        url = `${this.element.getAttribute('href')}?query=${this.searchQueryValue}`,
        tab = window.open(url, '_blank')
      ;

      if (tab) {
        tab.focus();
      } else {
        window.Turbo.visit(url);
      }
    }

    if (this.searchIdValue && this.searchIdValue > 0) {
      const path = window.$Gunfinder.locale === 'de' ? '/suche/convert' : '/search/convert';
      await window.$Gunfinder.fetch(`${path}?search_id=${this.searchIdValue}&item_id=${this.idValue}`, { method: 'POST' });
    }
  }

  isFavorite () {
    return window.$Gunfinder.user.favorites.indexOf(this.idValue) > -1;
  }

  get appController () {
    return this.application.getControllerForElementAndIdentifier(document.querySelector('body'), 'app');
  }

  toggleFavorite (e) {
    e.preventDefault();

    const exec = () => {
      window.$Gunfinder.fetch(`/profile/favorites/${this.idValue}/toggle`);

      let i;
      if ((i = window.$Gunfinder.user.favorites.indexOf(this.idValue)) > -1) {
        window.$Gunfinder.user.favorites.splice(i, 1);
      } else {
        window.$Gunfinder.user.favorites.push(this.idValue);
      }
    };

    if (!window.$Gunfinder.user.signedIn) {
      this.appController.authController.open({ mode: 'favorite', callback: () => {
        exec();
        this.evalFavorite();
      } });

      return;
    }
    
    exec();
    this.evalFavorite();

    if (!this.isFavorite()) {
      this.dispatch('unfavorited', { detail: { el: this.element } });
    }
  }

  evalFavorite () {
    if (this.isFavorite()) {
      this.element.classList.add('favorite');
    } else {
      this.element.classList.remove('favorite');
    }
  }
}
